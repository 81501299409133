<template>
  <div>
    <b-button v-b-modal.modal-1 variant="success">Add Zone</b-button>
    <b-modal id="modal-1" title="Create New Zone">
      <b-form @submit.prevent="submitZoneForm" enctype="multipart/form-data">
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-2" label="Code:" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="zone_form.code"
                placeholder="Enter Zone Code"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Total area:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="number"
                v-model="zone_form.area"
                placeholder="Enter Total area (In Acres)"
                required
              ></b-form-input>
            </b-form-group>

            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addZoneBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(boundary, index) in zone_form.boundaries"
                      :key="index"
                    >
                      <td class="small align-middle">{{ index + 1 }}</td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.latitude"
                          size="sm"
                          placeholder="Enter Latitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.longitude"
                          size="sm"
                          placeholder="Enter Longitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-danger"
                          type="button"
                          @click="removeZoneBoundary(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitZoneForm()">
          Register
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zone_form: {
        name: "",
        area: "",
        boundaries: [],
      },
      success: false,
    };
  },
  methods: {
    submitZoneForm() {
      let project_id = this.$route.params.id;
      this.zone_form["_projectId"] = project_id;
      window.axios.post("zone", this.zone_form).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          this.zone_form.name = "";
          this.zone_form.area = "";
          this.zone_form.boundaries = [];
          this.$emit("zone-create", resp.data.data);
          this.success = resp.data.message;
          setTimeout(() => (this.success = false), 3000);
        }
      });
    },
    addZoneBoundary() {
      this.zone_form.boundaries.push({
        latitude: "",
        longitude: "",
      });
    },
    removeZoneBoundary(index) {
      this.zone_form.boundaries.splice(index, 1);
    },
  },
};
</script>
