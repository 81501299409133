<template>
  <div>
    <b-card class="zones">
      <b-table
        id="zone-datatable"
        class="dataTable zone-table"
        :outlined="true"
        :items="zoneDetails"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="zonesHeaders"
        :caption-top="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{ (currentPage - 1) * 10 + (data.index + 1) }}</span>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(action)="row">
          <router-link :to="'/zone/' + row.item._id"
            ><i class="fas fa-eye view-icon" aria-hidden="true"></i>
          </router-link>
        </template>

        <template #table-caption>Zones</template>
      </b-table>
      <div v-if="rows > 10" class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="zone-data-table"
          class="greenPagination"
        ></b-pagination>
      </div>
    </b-card>

    <b-modal title="Edit Zone" v-model="showEditModal">
      <b-form @submit.prevent="submitZoneForm" enctype="multipart/form-data">
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-2" label="Code:" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.code"
                placeholder="Enter Zone Code"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Total area:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.area"
                placeholder="Enter Total area (In Acres)"
                required
              ></b-form-input>
            </b-form-group>

            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addZoneBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(boundary, index) in form.boundaries"
                      :key="index"
                    >
                      <td class="small align-middle">{{ index + 1 }}</td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.latitude"
                          size="sm"
                          placeholder="Enter Latitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.longitude"
                          size="sm"
                          placeholder="Enter Longitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-danger"
                          type="button"
                          @click="removeZoneBoundary(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitZoneForm()">
          Update
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["zones"],
  mounted() {
    this.zoneDetails = this.zones;
  },
  data() {
    return {
      form: {
        name: "",
        area: "",
        boundaries: [],
        _id: "",
      },
      showEditModal: false,
      zoneDetails: [],
      zonesHeaders: [
        "#",
        {
          key: "code",
          label: "Zone ID",
          sortable: true,
        },
        {
          key: "area",
          label: "Area (in acrs)",
          sortable: true,
        },
        "action",
      ],
      sortBy: "code",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.zones.length;
    },
  },
  methods: {
    addZoneBoundary() {
      this.form.boundaries.push({
        latitude: "",
        longitude: "",
      });
    },
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    removeZoneBoundary(index) {
      this.form.boundaries.splice(index, 1);
    },
    showEditModalMethod(index) {
      this.showEditModal = true;
      this.form.name = this.zones[index].name;
      this.form.code = this.zones[index].code;
      this.form.area = this.zones[index].area;
      this.form.boundaries = this.zones[index].boundaries;
      this.form._id = this.zones[index]._id;
    },
    submitZoneForm() {
      let project_id = this.$route.params.id;
      window.axios.get("zone/" + project_id, this.zone_form).then((resp) => {
        console.log(resp);
      });
    },
  },
};
</script>

<style scoped>
.zones {
  border: none;
}
.zones .card-body {
  padding: 0;
}
.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}
/deep/ .dataTable {
  text-align: center;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
}
/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
/deep/ .dataTable caption {
  text-align: center;
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-top: 19px;
  padding-bottom: 12px;
  font-size: 20px;
}
/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Roboto", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}
/deep/ .zone-table {
  border-radius: 0;
  box-shadow: none;
}
/deep/ .greenPagination > li > a {
  color: #1da040;
}
/deep/ .greenPagination > li.active > a,
/deep/ .greenPagination > li > a:hover {
  color: white;
  background-color: #1da040 !important;
}
</style>
