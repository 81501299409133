<template>
  <div>
    <b-row>
      <b-col md="12">
        <h5>Farm Details</h5>
        <b-card class="project-details">
          <b-row no-gutters>
            <b-col md="5" class="card-text">
              <p class="details">Name: {{ projectDetails.name }}</p>
              <p class="details">
                Org Code:
                <span class="description">{{ projectDetails.code }}</span>
              </p>
              <p class="details">
                Area (in acrs):
                <span class="description">{{ projectDetails.area }}</span>
              </p>
              <p class="details">
                Description:
                <span class="description">{{
                  projectDetails.description
                }}</span>
              </p>

              <p v-if="projectDetails.location" class="details">
                Location:
                <span class="description">{{ projectDetails.location }}</span>
              </p>
            </b-col>
            <b-col md="7">
              <img :src="projectDetails.image" alt="Farm Image" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ["projectDetails"],
  methods: {
    getProjectDetails() {
      console.log(this.projectDetails.image);
    },
  },
  mounted() {
    this.getProjectDetails();
  },
};
</script>

<style scoped>
.project-details {
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.project-details > .card-body {
  padding: 0%;
}

.details {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.035em;
  color: #000;
}

.card-text {
  text-align: left;
  padding: 35px 25px 10px;
  background-color: #dcffd9;
}

.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 29.44px;
}

img {
  height: 360px;
  width: 100%;
  border-radius: 0 11px 11px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
</style>
