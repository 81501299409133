<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <project-detail :projectDetails="projectDetails"></project-detail>
      <b-row class="mt-4 mb-4">
        <b-col md="4">
          <activities-graph
            :activity-data="projectActivities"
            :is-data-available="projectDataAvailable"
            :chart-labels="labels"
            :view-link="projectActivityDetailsLink"
            title="Project Level Activities"
          ></activities-graph>
        </b-col>
        <b-col md="4">
          <activities-graph
            :activity-data="zoneActivities"
            :is-data-available="zoneDataAvailable"
            :chart-labels="labels"
            :view-link="zoneActivityDetailsLink"
            title="Zone Level Activities"
          ></activities-graph>
        </b-col>
        <b-col md="4">
          <activities-graph
            :activity-data="plotActivities"
            :is-data-available="plotDataAvailable"
            :chart-labels="labels"
            :view-link="plotActivityDetailsLink"
            title="Plot Level Activities"
          ></activities-graph>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col md="8">
          <b-row>
            <b-col md="2">
              <b-form-select v-model="item_per_page" @change="changeItemNumber">
                <b-form-select-option value="10">10</b-form-select-option>
                <b-form-select-option value="50">50</b-form-select-option>
                <b-form-select-option value="100">100</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col md="10">
              <b-form-group label="" label-for="filter-input" class="mb-0">
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" class="text-right">
          <zone-create @zone-create="zoneListReload"></zone-create>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="8">
          <b-table
            class="dataTable rounded-left"
            :outlined="true"
            :items="items"
            :fields="fields"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            head-variant="none"
            table-variant="none"
            :busy="isBusy"
            :caption-top="true"
          >
            <template #table-caption>Owners</template>
            <template #cell(#)="data">
              <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
              <span v-else>{{
                (currentPage - 1) * item_per_page + (data.index + 1)
              }}</span>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div v-if="rows > 10" class="d-flex justify-content-center mt-4">
            <b-pagination
              pills
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col>
        <b-col md="4">
          <b-row>
            <b-col md="12">
              <zone-list :zones="zoneDetails" v-if="zoneDetails"></zone-list>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ZoneCreate from "@/components/projects/ZoneCreate";
import ZoneList from "@/components/projects/ZoneList";
import ProjectDetail from "@/components/projects/ProjectDetails";
import ActivitiesGraph from "@/components/charts/ActivitiesGraph";
export default {
  components: {
    ZoneCreate,
    ZoneList,
    ProjectDetail,
    ActivitiesGraph,
  },
  data() {
    return {
      projectDetails: false,
      zoneDetails: false,
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortBy: "name",
      sortDesc: false,
      filter: null,
      isBusy: false,
      fields: [
        "#",
        {
          key: "userName",
          label: "Name",
          sortable: true,
        },
        {
          key: "userEmail",
          label: "Email",
          sortable: false,
        },
        {
          key: "userPhn",
          label: "Phone No.",
          sortable: false,
        },
      ],
      items: [],
      users: [],
      activities: [],
      labels: ["Completed", "In Progress", "Pending"],
      projectCode: null,
      plotActivities: [],
      zoneActivities: [],
      projectActivities: [],
      plotDataAvailable: true,
      zoneDataAvailable: true,
      projectDataAvailable: true,
      assignmentLevel: "",
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    projectActivityDetailsLink() {
      return `/activities/${this.projectCode}/project_level`;
    },
    zoneActivityDetailsLink() {
      return `/activities/${this.projectCode}/zone_level`;
    },
    plotActivityDetailsLink() {
      return `/activities/${this.projectCode}/plot_level`;
    },
  },
  mounted() {
    this.getProjectDetails();
    this.getProjectZoneDetails();
    this.getActivitiesData();
    this.fetchOwners();
  },
  methods: {
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    zoneListReload() {
      this.getProjectZoneDetails();
    },
    getProjectDetails() {
      let project_id = this.$route.params.id;
      window.axios.get("project/" + project_id).then((resp) => {
        this.projectDetails = resp.data.data;
        this.projectCode = this.projectDetails.code;
      });
    },

    getProjectZoneDetails() {
      let project_id = this.$route.params.id;
      window.axios.get("project-zones/" + project_id).then((resp) => {
        this.zoneDetails = resp.data.data;
      });
    },
    getActivitiesData() {
      let project_id = this.$route.params.id;
      window.axios("project/" + project_id + "/activities").then((resp) => {
        let activities = resp.data.data;
        console.log(activities);
        this.projectActivities = [
          activities.project_level.completed,
          activities.project_level.inProgress,
          activities.project_level.pending,
        ];
        this.zoneActivities = [
          activities.zone_level.completed,
          activities.zone_level.inProgress,
          activities.zone_level.pending,
        ];
        this.plotActivities = [
          activities.plot_level.completed,
          activities.plot_level.inProgress,
          activities.plot_level.pending,
        ];
        if (this.plotActivities.length === 0) {
          this.plotDataAvailable = false;
        }
        if (this.zoneActivities.length === 0) {
          this.zoneDataAvailable = false;
        }
        if (this.projectActivities.length === 0) {
          this.projectDataAvailable = false;
        }
      });
    },

    fetchOwners() {
      this.isBusy = true;
      let project_id = this.$route.params.id;
      window
        .axios("project/" + project_id + "/customers")
        .then((resp) => {
          this.items = resp.data.data;
          this.isBusy = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
/deep/ .dataTable {
  text-align: center;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
}
/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
/deep/ .dataTable caption {
  text-align: center;
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-top: 19px;
  padding-bottom: 12px;
  font-size: 20px;
}
/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Roboto", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>
