<template>
  <div>
    <b-card class="activities-graph">
      <b-card-header class="p-0 card-header">
        <h3 class="pt-3 heading">{{ title }}</h3>
      </b-card-header>
      <b-card-body>
        <div v-if="isDataAvailable === false">
          <div class="text-center text-danger m-5 big-div">
            <p class="text-bold">No Data Available</p>
          </div>
        </div>
        <div v-else-if="isDataAvailable === true && activityData.length == 0">
          <div class="text-center text-danger m-5 big-div">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
        <apexchart
          v-else
          width="350"
          type="donut"
          :options="options"
          :series="activityData"
        ></apexchart>
      </b-card-body>
      <b-card-footer class="p-0 m-0">
        <router-link :to="viewLink" class="router-link"
          ><section class="bottom-button">View</section></router-link
        >
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ActivitiesGraph",
  props: {
    title: {
      type: String,
      required: true,
    },
    activityData: {
      type: Array,
      required: true,
    },
    viewLink: {
      type: String,
      required: true,
    },
    isDataAvailable: {
      type: Boolean,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: "activities-chart",
        },
        labels: this.chartLabels,
        colors: ["#219653", "#FFC062", "#00c0ef"],
        plotOptions: {
          pie: {
            donut: {
              size: "71%",
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.card-body {
  padding: 0;
}

.activities-graph {
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.card-header {
  height: 55px;
  background-color: #dcffd9;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.heading {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 3.5%;
  font-weight: 400;
}

.bottom-button {
  background-color: #009e2a;
  height: 51px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  letter-spacing: 3.5%;
  font-weight: 500;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  line-height: 30px;
  padding-top: 11px;
  border-radius: 0px 0px 11px 11px;
}

.big-div {
  height: 104px;
}

/deep/ .apexcharts-legend {
  margin-top: 30px;
  margin-left: 50px;
}

.router-link {
  text-decoration: none;
}
</style>
